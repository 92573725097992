import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import {DateTime} from "luxon";
import OsintAxios from "@/axios/osint.js";
import rawAxios from "@/axios";
import NeoAnalyse from "@/components/analyse";
import Pagination from "@/components/pagination";
import neoSwitch from "@/components/toggle-switch";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import {mapGetters} from "vuex";

export default {
    name: "domain-section",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        NeoAnalyse,
        neoPagination: Pagination,
        neoSwitch,
    },
    props: {
        domainData: {
            type: Object,
            default: "",
        },
        domain: {
            type: String,
            default: "",
        },
        isMonitoringTab: {
            type: Boolean,
            default: false,
        },
        estimatedDomainAge: {
            type: String,
            default: "",
        },
        queryId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // domainData: {},
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            subdomains: [],
            fetchedSubdomains: false,
            case_id: "",
            currentPage: 1,
            perPage: 20,
        };
    },
    computed: {
        ...mapGetters(["getProduct"]),
        sortedSubdomains() {
            return this.subdomains.sort();
        },
        getTotalPages() {
            return Math.ceil(this.subdomains.length / this.perPage);
        },
        // paginatedDomains() {
        //     return this.
        // }
    },
    methods: {
        getBackgroundStatus,
        async fetchSubdomains() {
            const {data} = await OsintAxios.get(`/domain/subdomains/${this.domain}`, {headers: {"x-query-id": this.queryId}});
            this.fetchedSubdomains = true;
            this.subdomains = data?.data;
        },
        handlePageChange(page) {
            this.currentPage = page;
        },
        getFormattedTime(ts, format = "EEE, dd MMM yyyy, hh:mm:ss a") {
            let dt = DateTime.fromISO(ts);
            if (dt.toFormat(format) != "Invalid DateTime") return dt.toFormat(format);
            else return "";
        },
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },
        async handleDone({target}) {
            let doc_id = this.domainData.monitoring_id;
            let sources = ["domain-search-tool"];
            // this.domainData.changes_viewed = !this.domainData.changes_viewed;
            this.$emit("updateDomainDetail", "changes_viewed");
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: target.checked,
            });
        },
        async handleMonitoring() {
            let card_source = "domain-search-tool";
            let card_id = this.domainData.doc_id;
            let status = this.domainData.monitoring_status;
            // this.domainData.monitoring_status = !this.domainData.monitoring_status;
            this.$emit("updateDomainDetail", "monitoring_status");
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source,
                status: status ? "enabled" : "disabled",
            });
        },
    },
};
